// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none;
font-family: "Manrope", sans-serif;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,UAAU;EACV,qBAAqB;EACrB,qBAAqB;AACvB,kCAAkC;EAChC,sBAAsB;AACxB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');\n*{\n  margin: 0;\n  padding: 0;\n  list-style-type: none;\n  text-decoration: none;\nfont-family: \"Manrope\", sans-serif;\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
